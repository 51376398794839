import React from "react"
import { Videos } from "../../data/assets"
import { Routes } from "../../data/routes"
import Card from "../../components/card"
import { ClosingCTA } from "../../components/CTA"
import Img from "../../components/img"
import Layout from "../../components/layout"
import PageHeader from "../../components/PageHeaderBlueH1"
import Seo from "../../components/seo"
import SocialProof from "../../components/SocialProof"
import { PrimaryButton, SecondaryButton } from "../../components/button"
import Video from "../../components/Video"

const Content = {
  pageName: "Yup Online Math Tutoring Service - Math Tutoring Website",
  subheader: "Online Math Tutoring",
  header: `Accelerate student learning. Expand teacher capacity.`,
  description: `Yup is your math teacher multiplier. We provide secure access to
    expert math tutors 24/7, powering High-Dosage Tutoring in and out of the
    classroom. Our "Math Learning Support System" gives teachers extra capacity,
    actionable data, and is like having a teaching assistant in every classroom.`,
  video: Videos.yupForSchools,
  headerCTALink: Routes.schools,
  headerCTAText: "Schedule Demo",

  // Stats section
  statsHeader: "Impact to date",
  stats: [
    {
      stat: ">25k",
      image: "icon-students.svg",
      imagealttext: "Group of students",
      description: "school students served",
    },
    {
      stat: ">300k",
      image: "icon-laptop.svg",
      imagealttext: "Person typing on laptop",
      description: "tutoring sessions",
    },
    {
      stat: ">160k",
      image: "icon-clock.svg",
      imagealttext: "Clock face",
      description: "teacher hours saved",
    },
  ],

  // Mid page CTA
  midPageCTA: "Want to learn more?",
  midPageCTAACtions: [
    {
      link: Routes.features,
      text: "How Yup works",
    },
  ],

  // Values section
  valuesTop: [
    {
      value:
        "Capture the benefits of High-Dosage Tutoring in and outside of the classroom",
      image: "icon-enrichment.svg",
      imageAltText: "Lightbulb wearing mortarboard",
      description: `Yup's unlimited, on-demand math tutoring supplements classroom-based
        learning throughout the school day and at home. Regardless of the type or
        structure of your school, Yup will work with you to ensure your students and
        staff get the most out of employing effective, evidence-based tutoring practices.`,
      action: "Learn more",
      link: Routes.highDosageTutoring,
    },
    {
      value:
        "Activate the power of putting a Teaching Assistant in every classroom",
      image: "icon-socratic-method.svg",
      imageAltText: "Lightbulb wearing mortarboard",
      description: `Tutoring works best when tutors are connected to the classroom and
        work in collaboration with teachers. Yup provides teachers their own dashboard
        with real-time usage data - including session transcripts - and the ability
        to give instructional guidance to students and tutors. Teachers also receive
        weekly reports with data-driven insights.`,
      action: "Learn more",
      link: Routes.teachers,
    },
  ],

  // Values section
  values: [
    {
      value: "Tutoring that drives long-term learning",
      video: Videos.guidingQuestions,
      description: `Yup's evidence-based Teaching Framework draws heavily from the
        The National Council of Teachers of Mathematics' (NCTM) Teaching Practices.
        Our Framework encourages tutors to use a question-based approach to uncover
        misconceptions and develop conceptual understanding while making sure students
        feel heard and supported.`,
      action: "How we teach",
      link: Routes.learningFundamentals,
    },
    {
      value: "Expert tutors who you can trust",
      video: Videos.expertTutors,
      description: `Our rigorous application process ensures tutors have strong math
        fundamentals and effective written communication skills. Less than 5% of
        applicants are accepted. In addition, tutors receive ongoing training and
        support from our education experts to ensure continued student success.`,
      action: "Meet our tutors",
      link: Routes.ourTutors,
    },
    {
      value: "Guaranteed privacy and security",
      image: "icon-information.svg",
      imageAltText: "Lightbulb wearing mortarboard",
      description: `Working with students means privacy and security is always our highest priority.
      Yup guarantees compliance—and beyond—with Federal and State policies to ensure safe, fun and
      productive learning interactions.`,
      action: "Student privacy",
      link: Routes.privacy,
    },
  ],

  // Closing CTA
  closingCTA: "Learn what it means to bring Yup to your school or district",
  closingCTAActions: [
    {
      link: Routes.schools,
      text: "Schedule demo",
    },
  ],
}

function StatsSection() {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/stats-sections#component-da2f699b57da2cabac8c760a8d34f6a6
  return (
    <div className="bg-blue-800">
      <div className="max-w-7xl mx-auto py-8 px-4 sm:py-8 sm:px-6 lg:px-8 lg:py-8">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
            {Content.statsHeader}
          </h2>
        </div>
        <dl className="mt-10 text-center sm:max-w-3xl sm:mx-auto sm:grid sm:grid-cols-3 sm:gap-8">
          {Content.stats.map(stat => (
            <div className="flex flex-col py-2 mt-2" key={stat.description}>
              <dt>
                <Img
                  src={stat.image}
                  alt={stat.imagealttext}
                  className="mx-auto mb-4 h-32 w-auto"
                />
              </dt>
              <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-100">
                {stat.description}
              </dt>
              <dd className="order-1 text-5xl font-extrabold text-white">
                {stat.stat}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  )
}

function TeachingSectionTop() {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/cta-sections#component-58b6441d042e26470c2d485039ead146
  return (
    <Card>
      {Content.valuesTop.map((section, index) => (
        <div
          className={`flex flex-col ${
            index % 2 ? "md:flex-row" : "md:flex-row-reverse"
          } max-w-7xl mx-auto items-center`}
          key={`teaching-section-${index}`}
        >
          <div className="w-full p-4 flex-grow md:w-1/2">
            {section.video ? (
              <Video url={section.video.url} image={section.video.cover} />
            ) : (
              <Img
                src={section.image}
                alt={section.imageAltText}
                className="w-full h-full max-w-sm object-fit mx-auto"
              />
            )}
          </div>
          <div className="text-center md:text-left w-full py-12 md:w-1/2">
            <div className="md:ml-auto md:px-10">
              <p className="mt-2 text-gray-900 text-3xl font-extrabold tracking-tight sm:text-4xl">
                {section.value}
              </p>
              {section.description.split(/\n\n/g).map((description, i) => (
                <p
                  className="mt-3 text-lg text-gray-500"
                  key={`teaching-section-${index}-${i}`}
                >
                  {description}
                </p>
              ))}
              <div className="mt-8">
                <SecondaryButton href={section.link} text={section.action} />
              </div>
            </div>
          </div>
        </div>
      ))}
    </Card>
  )
}

function TeachingSection() {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/cta-sections#component-58b6441d042e26470c2d485039ead146
  return (
    <Card>
      {Content.values.map((section, index) => (
        <div
          className={`flex flex-col ${
            index % 2 ? "md:flex-row" : "md:flex-row-reverse"
          } max-w-7xl mx-auto items-center`}
          key={`teaching-section-${index}`}
        >
          <div className="w-full p-4 flex-grow md:w-1/2">
            {section.video ? (
              <Video url={section.video.url} image={section.video.cover} />
            ) : (
              <Img
                src={section.image}
                alt={section.imageAltText}
                className="w-full h-full max-w-sm object-fit mx-auto"
              />
            )}
          </div>
          <div className="text-center md:text-left w-full py-12 md:w-1/2">
            <div className="md:ml-auto md:px-10">
              <p className="mt-2 text-gray-900 text-3xl font-extrabold tracking-tight sm:text-4xl">
                {section.value}
              </p>
              {section.description.split(/\n\n/g).map((description, i) => (
                <p
                  className="mt-3 text-lg text-gray-500"
                  key={`teaching-section-${index}-${i}`}
                >
                  {description}
                </p>
              ))}
              <div className="mt-8">
                <SecondaryButton href={section.link} text={section.action} />
              </div>
            </div>
          </div>
        </div>
      ))}
    </Card>
  )
}

const Home = () => {
  return (
    <Layout>
      <Seo
        title={Content.pageName}
        description={Content.description}
        route={Routes.home}
      />
      <PageHeader
        smallTitle={Content.subheader}
        mainTitle={Content.header}
        description={Content.description}
        video={Content.video}
        ctaLink={Content.headerCTALink}
        ctaText={Content.headerCTAText}
      />
      <SocialProof />
      <TeachingSectionTop />
      <StatsSection />
      <TeachingSection />
      <ClosingCTA
        title={Content.closingCTA}
        actions={Content.closingCTAActions}
      />
    </Layout>
  )
}

export default Home
